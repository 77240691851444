import "popper.js";
import "./scss/styles.scss"
import "bootstrap";
import { get } from "jquery";
const setAttributes = function (el, attrs) {
	for (var key in attrs) {
		el.setAttribute(key, attrs[key]);
	}
};


/*function handleForm() {
	console.log(document.getElementById("myForm").style.display);
	if (
		document.getElementById("myForm").style.display === "none" ||
		!document.getElementById("myForm").style.display.length
	) {
		document.getElementById("myForm").style.display = "block";
		document.getElementById("opaChange").style.opacity = "0.4";
	} else {
		document.getElementById("myForm").style.display = "none";
		document.getElementById("opaChange").style.opacity = "1";
	}
}

window.handleForm = handleForm;*/

function isSelected() {

	let timeLabel = document.getElementById("timeLabel");
	let timeInput = document.getElementById("timeInput");

	if (document.getElementById("selectSend").value === 'direct') {
		timeInput.required = false;
		timeInput.hidden = true;
		timeLabel.hidden = true;
	} else {
		timeInput.required = true;
		timeInput.hidden = false;
		timeLabel.hidden = false;
	}
}

window.isSelected = isSelected;

function setCookie(name, value, days) {
	let expires = "";
	if (days) {
		let date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toUTCString();
	}
	document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
	let nameEQ = name + "=";
	let ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

function isCookieConsentSet() {
	const cookieParent = document.querySelector("#cookieParent")
	if (getCookie('cookie-consent') === null) {
		cookieParent.classList.replace('cookies-hide', 'cookies-show')
	} else if (getCookie('cookie-consent') != null) {
		cookieParent.classList.replace('cookies-show', 'cookies-hide')
		// Cookie consent has been set by user
		if (getCookie('cookie-consent') === '1') {
			// Cookie consent has been given
			gtag('consent', 'update', {
				'ad_storage': 'granted',
				'analytics_storage': 'granted'
			});
		}
	}
}

function eraseCookie(name) {
	document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

window.dataLayer = window.dataLayer || [];

function gtag() {
	dataLayer.push(arguments);
}

const yesCookiesBtn = document.querySelector("#yesCookies");

yesCookiesBtn.addEventListener('click', (event) => {
	setCookie('cookie-consent', '1', 30)
	isCookieConsentSet();
})

const noCookiesBtn = document.querySelector("#noCookies");

noCookiesBtn.addEventListener('click', (event) => {
	setCookie('cookie-consent', '0', 30)
	isCookieConsentSet();
})

isCookieConsentSet();
